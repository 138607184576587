import { env } from "md-base-tools/env";
export default {
  name: "lottery",
  head() {
    return {
      title: "大预言家"
    };
  },
  filters: {
    time: function (str) {
      return str;
    }
  },
  components: {},
  computed: {
    isIOS() {
      return env.isModianIos();
    }
  },
  data() {
    return {
      env,
      isIphoneX: "",
      statusBarH: null,
      list: [],
      time: 0,
      day: 0,
      hour: 0,
      minute: 0,
      sec: 0,
      page: 1,
      total: 10,
      nacidx: 1,
      dialogShow: false,
      loading: false
    };
  },
  beforeMount() {},
  mounted() {
    // eslint-disable-next-line no-undef
    graft.app({
      functionName: "onGetDeviceInfo",
      functionParams: {},
      callBack: e => {
        this.statusBarH = e.data.statusBarH;
      }
    });
  },
  methods: {
    close() {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "closeController",
        functionParams: ""
      });
    }
  }
};